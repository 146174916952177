<template>
  <div>
    <div id="livraison" @click="routerOT(resultExpData.id)">
      <div id="top_lv">
        <div class="icone">
          <img v-if="resultExpData.statutsuivi === 0" src="../../public/images/camion_gris.png" title="Aucune information"/>
          <img v-if="resultExpData.statutsuivi === 1" src="../../public/images/camion_vert.png" title="Livraison effectuée"/>
          <img v-if="resultExpData.statutsuivi === 2" src="../../public/images/camion_jaune.png" title="En cours de livraison"/>
          <img v-if="resultExpData.statutsuivi === 3" src="../../public/images/camion_orange.png" title="Anomalie potentielle"/>
          <img v-if="resultExpData.statutsuivi === 4" src="../../public/images/camion_rouge.png" title="Livraison impossible"/>
        </div>
        <div class="ecrit">
          <div class="info">
            <p>{{ resultExpData.numero }}</p>
          </div>
          <div class="info_destinataire">
            <p>{{ resultExpData.destinataire_nom }}</p>
            <p>{{ resultExpData.destinataire_codepostal }} {{ resultExpData.destinataire_ville }}</p>
          </div>
          <div class="info">
            <p>{{ resultExpData.transporteur_nom }}</p>
          </div>
          <div class="info">
            <p>{{ resultExpData.tournee_nom }}</p>
          </div>
          <div class="info">
            <p v-if="resultExpData.consignation_datecloture !== null">{{ formattedDateCloture }}</p>
            <p v-else>Non clôturé</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatDateMixin from '../mixins/formatDateMixin.js'
import router from '../router/index.js'

export default {
  name: 'ResultExpData',
  data: () => ({
    
  }),
  computed: {
    formattedDateCloture() {
      return this.formatDate(this.resultExpData.consignation_datecloture)
    }
  },
  mixins: [
    formatDateMixin
  ],
  props: [
    'resultExpData'
  ],
  methods: {
    routerOT(id) {
      router.push('/expediteur/resultats/' + id)
    }
  }
}

</script>

<style scoped lang="scss">
  img {
    width: 35%;
  }
  #livraison {
    width: 80%;
    color: #23262c;
    margin: 1rem auto;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: rgb(236, 239, 241);
    font-size: 1em;
    transition: background 0.5s ease-in-out;
    overflow: hidden;
    &:hover {
      background: rgb(240, 255, 255);
    }
  }
  #top_lv {
    display: flex;
  }
  .info {
    width: 17%;
  }
  .info_destinataire {
    width: 25%;
  }
  .ecrit {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  #infos_tournee {
    width: 75%;
    display: flex;
  }
  #bottom_lv {
    display: flex;
    justify-content: space-around;
  }
  .statutSuivi {
    width: 15%;
    right: 0px;
    text-align: center;
  }
  .icone {
    width: 10%;
    height: 53px;
    text-align: center;
  }
  @media screen and (max-width: 1400px) {
    .icone {
      width: 12%;
    }
  }
  @media screen and (max-width: 1700px) {
    .icone {
      width: 15%;
    }
  }
</style>