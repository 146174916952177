import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ResultExpData from './components/ResultExpData.vue'
import SuiviData from './components/SuiviData.vue'
import ExpediteurFiltersData from './components/ExpediteurFiltersData.vue'
import PointageDestData from './components/PointageDestData.vue'
import UMData from './components/UMData.vue'


createApp(App).use(store).use(router).component(['ResultExpData', 'SuiviData', 'ExpediteurFiltersData', 'PointageDestData', 'UMData'], [ResultExpData, SuiviData, ExpediteurFiltersData, PointageDestData, UMData]).mount('#app')
