<template>
    <td v-if="suiviData.dtm_date" class="infos_suivi">{{ formattedDate }}</td>
    <td class="infos_suivi">{{ suiviData.cta_acteur }}</td>
    <td class="infos_suivi">{{ suiviData.rsj_situation }} {{ suiviData.rsj_justification }} {{ suiviData.situationjustification_libelle }}</td>
    <td v-if="suiviData.dtm_datelivraisonestimee" class="infos_suivi">{{ formattedDateLivraisonEstimee }}</td>
    <td v-if="suiviData.dtm_datenouveaurdv" class="infos_suivi">{{ formattedDateNouveauRdv }}</td>
    <!-- cta_modecommunication et cta_numero -->
</template>

<script>
  import formatDateMixin from '../mixins/formatDateMixin.js'

  export default {
    name: 'Suivi',
    props: [
      'suiviData'
    ],
    mixins: [
      formatDateMixin
    ],
    computed: {
      formattedDate() {
        return this.formatDate(this.suiviData.dtm_date)
      },
      formattedDateLivraisonEstimee() {
        return this.formatDate(this.suiviData.dtm_datelivraisonestimee)
      },
      formattedDateNouveauRdv() {
        return this.formatDate(this.suiviData.dtm_datenouveaurdv)
      }
    },
  }
</script>

<style scoped lang="scss">
.infos_suivi {
  padding: 0.5rem 0;
}
</style>
