export default {
    methods: {
        formatDate(dateString) {
            try {
            const date = new Date(dateString)

            let options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}
            options.timeZone = 'UTC'

            return new Intl.DateTimeFormat('fr', options ).format(date)
            } catch {
                return
            }
        },
        date_diff(date_debut, date_fin) {
            let res = ''
            if (date_debut > date_fin) {
                res = 'error'
            }
            return res
        }
    }
}
