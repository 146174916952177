import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "homepage" */ '../views/Homepage.vue')
  },
  {
    path: '/suivi/:ref/:cp/:id',
    name: 'result_codepostal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "result_codepostal" */ '../views/Result.vue')
  },
  {
    path: '/expediteur',
    name: 'expediteur',
    component: () => import(/* webpackChunkName: "expediteur" */ '../views/Expediteur.vue')
  },
  {
    path: '/expediteur/resultats',
    name: 'resultExp500',
    component: () => import(/* webpackChunkName: "resultExp500" */ '../views/ResultExp500.vue')
  },
  {
    path: '/expediteur/resultats/:id',
    name: 'uniqueResultExp',
    component: () => import(/* webpackChunkName: "uniqueResultExp" */ '../views/UniqueResultExp.vue')
  },
  {
    path: '/expediteur/filtres/:filtres(.*)', // path sur ce qui COMMENCE par expediteur/filtres/
    name: 'expediteurFilters',
    component: () => import(/* webpackChunkName: "expediteurFilters" */ '../views/ExpediteurFilters.vue')
  },
  {
    path: '/expediteur/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  let storageToken = {
    token: localStorage.getItem('token')
  }
  
  fetch(process.env.VUE_APP_ROOT_API + '/token', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(storageToken)
  })
  .then(res => res.json())
  .then(data => {
    if((to.name === 'expediteur' || to.name === 'uniqueResultExp' || to.name === 'resultExp500' || to.name === 'expediteurFilters') && (data.message === false || !storageToken)) {
      next({ name: 'login' })
    } else {
      next()
    }
  })
})


export default router