import router from '@/router'
import { createStore } from 'vuex'

export default createStore({
  state: {
    ordre_transport_liste: [],
    ordre_transport: [], 
    ordre_transport_suivi: [],
    ordre_transport_filtres: [],
    ordre_transport_um_dest: [],
    ordre_transport_pointage_dest: [],
    url_geolocalisation: '',
    message: '',
    message_suivi: '',
    message_connexion : '',
    message_login: '',
    message_password: '',
    token: ''
  },
  getters: {
    ordre_transport_liste(state) {
      return state.ordre_transport_liste
    },
    ordre_transport(state) {
      return state.ordre_transport
    }
  },
  mutations: {
    ORDRE_TRANSPORT_LISTE(state, data) {
      state.ordre_transport_liste = data
    },
    ORDRE_TRANSPORT(state, data) {
      state.ordre_transport = data,
      state.message = data.message,
      state.message_connexion = data.message_connexion
    },
    ORDRE_TRANSPORT_SUIVI(state, data) {
      state.ordre_transport_suivi = data,
      state.message_suivi = data.message
    },
    ORDRE_TRANSPORT_POINTAGE(state, data) {
      state.ordre_transport_pointage_dest = data,
      state.message = data.message
    },
    ORDRE_TRANSPORT_UM_DEST(state, data) {
      state.ordre_transport_um_dest = data
    },
    LOGIN(state, data) {
      state.message_login = data.message_login,
      state.message_password = data.message_password,
      state.token = data.token
    },
    ORDRE_TRANSPORT_FILTRES(state, data) {
      state.ordre_transport_filtres = data,
      state.message = data.message
    }
  },
  actions: {
    // Infos OT - DESTINATAIRE
    getOneOrder_codepostal({ commit }, { input, codepostal }) {

      fetch(process.env.VUE_APP_ROOT_API + '/OT/' + input + '/' + codepostal)
      .then(res => res.json())
      .then(data => {
        commit("ORDRE_TRANSPORT", data)
        if(!data.message && !data.message_connexion) {
          router.push('/suivi/' + input + '/' + codepostal + '/' + data[0].id)
        }
        console.log(data)
      })
    },
    getUM({ commit }, id) {

      fetch(process.env.VUE_APP_ROOT_API + '/UM/' + id)
      .then(res => res.json())
      .then(data => {
        commit("ORDRE_TRANSPORT_UM_DEST", data)
        console.log(data)
      })
    },
    getPointageDest({ commit }, id) {

      fetch(process.env.VUE_APP_ROOT_API + '/pointage/' + id)
      .then(res => res.json())
      .then(data => {
        commit("ORDRE_TRANSPORT_POINTAGE", data)
        console.log(data)
      })
    },
    // Infos suivi OT
    getOneOrder_id_suivi({ commit }, id) {

      fetch(process.env.VUE_APP_ROOT_API + '/suivi/' + id)
      .then(res => res.json())
      .then(data => {
        commit("ORDRE_TRANSPORT_SUIVI", data)
        console.log(data)
      })
      .catch(err => console.log(err))
    },
    // login - EXPEDITEUR
    login({ commit }, loginData) {
      console.log(loginData)
      fetch(process.env.VUE_APP_ROOT_API + '/login', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginData)
      })
      .then(res => res.json())
      .then(data => {
        commit('LOGIN', data)
        if(data.message_login === "" && data.message_password === "") {
          router.push('/expediteur')
        }
        localStorage.setItem('token', data.token)
      })
      .catch(err => console.log(err))
    },
    // Recherche filtres - EXPEDITEUR
    getOrderFilters({ commit }, { filterData, url_finale }) {

      let storageToken = localStorage.getItem('token')

      fetch(process.env.VUE_APP_ROOT_API + '/filters', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + storageToken
        },
        body: JSON.stringify(filterData)
      })
      .then(res => res.json())
      .then(data => {
        commit("ORDRE_TRANSPORT_FILTRES", data)
        if(!data.message) {
          router.push(url_finale)
        }
        console.log(data)
      })
      .catch(err => console.log(err))
    },

    // Refresh de la recherche avec filtres
    getOrderFiltersWithoutRouter({ commit }, filterData) {

      console.log(filterData)

      let storageToken = localStorage.getItem('token')

      fetch(process.env.VUE_APP_ROOT_API + '/filters', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + storageToken
        },
        body: JSON.stringify(filterData)
      })
      .then(res => res.json())
      .then(data => {
        commit("ORDRE_TRANSPORT_FILTRES", data)
        console.log(data)
      })
      .catch(err => console.log(err))
    },

    // 500 dernières - EXPEDITEUR
    getAllOrders({ commit }) {

      let storageToken = localStorage.getItem('token')

      fetch(process.env.VUE_APP_ROOT_API + '/500', {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + storageToken
        }
      })
      .then(res => res.json())
      .then(data => {
        commit("ORDRE_TRANSPORT_LISTE", data)
        console.log(data)
      })
      .catch(err => console.log(err))
    },
    // Infos d'un OT en particulier - EXPEDITEUR
    getOneOrder_id({ commit }, id) {

      let storageToken = localStorage.getItem('token')

      fetch(process.env.VUE_APP_ROOT_API + '/OT/' + id, {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + storageToken
        }
      })
      .then(res => res.json())
      .then(data => {
        commit("ORDRE_TRANSPORT", data)
        console.log(data)
      })
      .catch(err => console.log(err))
    }
  },
  modules: {
  }
})