<template>  
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  created() {
    document.title = "Suivi MT " + process.env.VUE_APP_USER
  }
}
</script>

<style scoped lang="scss">

</style>