<template>
  <div>
    <div @focus="UM_details" @focusout="no_UM_details" id="cliquable" tabindex="0">
      <div class="info_colis_um">
        <img v-if="umData.statutpointage === 0" src="../../public/images/colis_gris.png" title="Aucune information"/>
        <img v-if="umData.statutpointage === 1" src="../../public/images/colis_vert.png" title="Livraison effectuée"/>
        <img v-if="umData.statutpointage === 2" src="../../public/images/colis_jaune.png" title="En cours de livraison"/>
        <img v-if="umData.statutpointage === 3" src="../../public/images/colis_orange.png" title="Anomalie potentielle"/>
        <img v-if="umData.statutpointage === 4" src="../../public/images/colis_rouge.png" title="Livraison impossible"/>
      </div>
      <div class="info_um">
        <p><strong>Colis</strong> {{ umData.codebarres }} </p>
      </div>
      <div class="info_poids_um">
        <p v-if="umData.poids < 1000">{{ umData.poids }} g</p>
        <p v-else>{{ formattedPoids }} kg</p>
      </div>
      <div class="info_um">
        <p> {{ umData.marchandise }} </p>
      </div>
      <div class="info_um">
        <p><strong>Créé le</strong> {{ formattedDate }} </p>
      </div>
      <div v-if="UM_details_visible" id="pointage">
        <p v-if="message" id="no_pointage">Aucune information de suivi</p>
        <ul v-else>
          <li v-for="pointage in pointageDestResults" :key="pointage">
            <PointageDestData :pointageData="pointage"/>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import formatDateMixin from '../mixins/formatDateMixin.js'
  import PointageDestData from './PointageDestData.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'UMData',
    data: () => ({
      UM_details_visible: false
    }),
    props: [
      'umData'
    ],
    components: {
      PointageDestData
    },
    mixins: [
      formatDateMixin
    ],
    computed: {
      ...mapState({
        pointageDestResults: state => state.ordre_transport_pointage_dest || {},
        message : state => state.message
      }),
      formattedDate() {
        return this.formatDate(this.umData.datecreation)
      },
      formattedPoids() {
        return this.umData.poids / 1000
      },
    },
    methods: {
      getPointageDest : function () {
        let id = this.umData.id
        this.$store.dispatch('getPointageDest', id)
      },
      UM_details : function () {
        this.UM_details_visible = true
        this.getPointageDest()
      },
      no_UM_details : function () {
        this.UM_details_visible = false
        this.$store.commit('ORDRE_TRANSPORT_POINTAGE', '')
      }
    }
  }
</script>

<style scoped lang="scss">
img {
  width: 35px;
}
ul {
  list-style-type: none;
}
.info_colis_um {
  width: 10%;
  text-align: center;
}
.info_um {
  width: 20%;
  text-align: left;
}
.info_poids_um {
  width: 10%;
}
</style>