<template>
  <div id="pointage_details">
    <div class="info_pointage_geo" v-if="pointageData.gpo_latitude && pointageData.gpo_longitude">
      <img src="../../public/images/geolocalisation.png" @click="open()"/>
    </div>
    <div class="info_pointage">
      <p><strong>Pointé le</strong> {{ formattedDate }}</p>
    </div>
    <div class="info_pointage">
      <p><strong>par</strong> {{ pointageData.cta_acteur }}</p>
    </div>
    <div class="info_pointage">
      <p>{{ pointageData.libelle }}</p>
    </div>
    <div v-if="pointageData.txt_information || pointageData.txt_remarque" class="info_pointage">
      <p>{{ pointageData.txt_information }} {{ pointageData.txt_remarque }}</p>
    </div>
  </div>
</template>

<script>
  import formatDateMixin from '../mixins/formatDateMixin.js'
  import { mapState } from 'vuex'
  let url_geo

  export default {
    name: 'PointageDestData',
    props: [
      'pointageData'
    ],
    mixins: [
      formatDateMixin
    ],
    computed: {
      ...mapState({

      }),
      formattedDate() {
        return this.formatDate(this.pointageData.dtm_date)
      },
      open() {
        url_geo = 'https://www.google.com/maps/dir//' + (this.pointageData.gpo_latitude / 10000000) + ',' + (this.pointageData.gpo_longitude / 10000000)
        console.log(url_geo)
        window.open(url_geo)
      }
    }
  }
</script>

<style scoped lang="scss">
  img {
    width: 20px;
  }
  #pointage_details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0.5rem 0;
    border-bottom: 1px solid white;
  }
  .info_pointage {
    width: 20%;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .info_pointage_geo {
    width: 5%;
    text-align: center;
    cursor: pointer;
  }
</style>